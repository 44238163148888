<script>
import { gantt } from 'dhtmlx-gantt';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import DhmeAssemblyPlanningMixin from '@/modules/daiwa-house-modular-europe/AssemblyPlanning/DhmeAssemblyPlanning.mixin';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';

export default {
  name: 'DhmeProductionPlanningChart',
  mixins: [DhmeAssemblyPlanningMixin],
  computed: {
    ...mapGetters({
      focusedLane: 'dhmeProductionPlanningStore/focusedLane',
      plannedTasks: 'dhmeProductionPlanningStore/plannedProductionTasks',
      productionHalls: 'dhmeProductionPlanningStore/productionHalls',
      productionLocations: 'dhmeProductionPlanningStore/productionLocations',
    }),

    productionLanes() {
      let lanes = [];
      this.productionHalls.forEach((h) => {
        [
          ...new Set(
            this.productionLocations
              .filter((l) => l.assembly_hall === h.id)
              .map((l) => l.assembly_lane)
          ),
        ].forEach((lane) => {
          lanes.push({
            id: `${h.id}--${lane}`,
            title: `${h.title} (lane ${lane})`,
            text: `${h.title}-${lane}`,
            type: 'project',
            render: 'split',
          });
        });
      });
      return lanes;
    },
  },
  watch: {
    focusedLane(lane) {
      if (!lane) {
        this.parseData();
      }
    },
    plannedTasks(tasks) {
      if (tasks) {
        this.parseData();
      }
    },
    productionHalls(halls) {
      if (halls) {
        this.parseData();
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    gantt.clearAll();
    gantt.detachAllEvents();
  },
  methods: {
    init() {
      gantt.ext.zoom.init(this.zoomConfig);
      gantt.ext.zoom.setLevel('month');

      gantt.templates.rightside_text = (start, end, task) => {
        return null;
      };
      gantt.config.columns = [
        { name: 'title', label: 'Production Hall', width: '200' },
      ];

      gantt.config.inherit_scale_class = true;
      gantt.config.work_time = true;
      gantt.config.sort = true;
      gantt.config.readonly = true;
      gantt.config.open_split_tasks = false;
      gantt.config.scroll_size = 10;

      gantt.attachEvent('onTaskClick', this.onTaskClick);
      gantt.attachEvent('onTaskDblClick', this.onTaskRowClick);

      gantt.init('gantt_container');
      this.parseData();
    },
    setZoomLevel(level) {
      gantt.ext.zoom.setLevel(level);
    },
    showDate(data) {
      gantt.showDate(data);
    },
    parseData() {
      gantt.clearAll();
      gantt.parse({
        data: [
          ...this.productionLanes,
          ...this.plannedTasks.map((task) => {
            return {
              ...(task.id ? { id: task.id } : {}),
              title: `${task.task_project.name} (${task.task_type.custom_2})`,
              text: `${task.task_project.name} (${task.task_type.custom_2})`,
              description: task.description,
              number: task.number,
              parent: `${task.task_type.custom_3}--${task.task_type.custom_7}`,
              project: task.task_project.name,
              group: task.task_type.custom_2,
              color: stringToConstantColor(
                `${task.id}-${task.task_type.custom_2}`
              ),
              start_date: moment(task.planned_start).format('DD-MM-YYYY HH:mm'),
              end_date: moment(task.planned_end).format('DD-MM-YYYY HH:mm'),
            };
          }),
        ],
      });
      this.showDate(new Date());
    },

    onTaskClick(id) {
      const task = this.plannedTasks.find((t) => t.id === id);
      this.$store.commit('dhmeProductionPlanningStore/focus_on_task', task);
    },

    async onTaskRowClick(id) {
      this.$store.commit('dhmeProductionPlanningStore/focus_on_lane', id);
      this.$store.commit('dhmeProductionPlanningStore/make_request');

      let plannedTasks = this.plannedTasks.filter(
        (t) => `${t.task_type.custom_3}--${t.task_type.custom_7}` === id
      );
      const { tasks } = await queryTasksV2(plannedTasks[0].license, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-element-assembly'],
        },
        {
          column: 'project',
          operator: '=',
          values: plannedTasks.map((t) => t.task_project.id),
        },
      ]);

      let arr = id.split('--');
      let hallId = arr[0];
      let lane = arr[1];

      gantt.clearAll();
      gantt.parse({
        data: [
          ...this.productionLocations
            .filter(
              (l) => l.assembly_hall === hallId && l.assembly_lane === lane
            )
            .sort(
              (a, b) =>
                parseInt(a.assembly_location) - parseInt(b.assembly_location)
            )
            .map((l) => {
              return {
                id: l.id,
                title: l.assembly_location,
                text: l.assembly_location,
                type: 'project',
                render: 'split',
                open: false,
              };
            }),

          ...tasks.map((task) => {
            return {
              id: task.id,
              title: task.sbscode.label ?? task.sbscode?.code,
              text: task.sbscode.label ?? task.sbscode?.code,
              description: task.description,
              number: task.number,
              parent: task.task_type.custom_3,
              project: task.task_project.name,
              color: stringToConstantColor(
                `${task.id}-${task.task_type.custom_2}`
              ),
              start_date: moment(task.planned_start).format('DD-MM-YYYY HH:mm'),
              end_date: moment(task.planned_end).format('DD-MM-YYYY HH:mm'),
            };
          }),
        ],
      });

      this.$store.commit('dhmeProductionPlanningStore/request_success');
    },
  },
};
</script>

<template>
  <div
    id="gantt_container"
    style="border-radius: 10px"
    class="flex-1 overflow-hidden"
  />
</template>

<style scoped lang="scss">
@import '~dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';

::v-deep {
  .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }
  .gantt_task_row.gantt_selected .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }
  .gantt_scale_cell {
    user-select: none;
    &.current_date {
      background-color: lightgray;
    }
  }
  .gantt_task_cell {
    &.current_date {
      background-color: lightgray;
    }
  }
  .gantt_tree_indent {
    width: 10px;
  }
  .status {
    background-color: #1de9b6;
  }
}
</style>
